import React, { memo } from "react"
import Links from "./Links"
import isEqual from "lodash/isEqual"

type FooterProps = {|
  links?: Array,
  copyright?: string,
  offersPage?: boolean,
  product?: Object,
  isAtHomePage?: boolean,
  isNewSalesLandingPage?: Boolean,
|}

const Footer = ({
  links = [],
  copyright = "",
  offersPage = false,
  product = {},
  isAtHomePage = false,
  isNewSalesLandingPage = false,
}: FooterProps) => {
  const isShowStickyToast =
    !offersPage && isNewSalesLandingPage && product && !isAtHomePage
  const navigation = isAtHomePage ? links.footer_navigation : links.navigation
  const socialLink = isAtHomePage ? links.footer_social : links.social

  return (
    // Hidden JoinToday section on footer when product get from Chargify is null
    <footer className={`${isShowStickyToast ? "mb-60 md:mb-0" : "mb-0"}`}>
      <div
        className={"bg-gray-dark text-white pl-5 pb-45 pt-45 lg:pb-68 lg:pt-73"}
      >
        <Links
          links={navigation ? navigation.items : []}
          styles={{
            wrapper: "m-0 p-0 text-center",
            linkWrapper:
              "block pt-2 pr-5 pb-2 pl-5 md:inline-block md:pl-19 md:pr-19",
            url:
              "w-max-content mx-auto text-base leading-6none no-underline pb-17 font-inter-regular md:pb-0 hover:text-green-460",
          }}
        />
        <Links
          links={socialLink ? socialLink.items : []}
          styles={{
            wrapper: `flex justify-between lg:mt-56 lg:mb-48 mb-20 mt-38 ml-auto mr-auto ${
              isAtHomePage ? "w-300" : "w-socials-footer-wrapper"
            }`,
            linkWrapper: "text-center",
            icon: "text-6xl",
          }}
        />
        <p className="text-gray-700 m-0 text-2xs text-center leading-snug md:text-base font-inter-regular">
          {copyright}
        </p>
      </div>
    </footer>
  )
}

export default memo(Footer, (prevProps, nextProps) => {
  return isEqual(prevProps.product, nextProps.product)
})
