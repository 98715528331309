import React from "react"
import { RichText } from "prismic-reactjs"
import {
  linkResolver,
  htmlSerializerUpdateStyle,
} from "@lesmills/gatsby-theme-common"

type LinksProps = {|
  links?: Array,
  styles?: Object,
|}

const Links = ({ links = [], styles = {} }: LinksProps) => {
  return (
    <ul className={styles.wrapper}>
      {links.map((item, key) => {
        const icon = item.icon
        return (
          <li key={key} className={styles.linkWrapper}>
            {icon ? (
              <a
                href={item.link.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={icon.text}
              >
                <i className={`fa ${icon.text} ${styles.icon}`} />
              </a>
            ) : (
              <RichText
                render={item.navigation.raw}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializerUpdateStyle("", styles.url)}
              />
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default Links
